// @flow

import React from "react";
import {
    Layout,
    DashboardLayout,
    RequestHistory,
    withPrivateRoute,
} from "@containers";

/**
 * History Page
 */
const History = (props: *) => (
    <Layout {...props}>
        <DashboardLayout title="Historiek">
            <RequestHistory
                location={props.location}
                IdToLoadHistory={props.inspectionRequestId}
            />
        </DashboardLayout>
    </Layout>
);

export default withPrivateRoute(
    History,
    "/aanvragen/:inspectionRequestId/historiek",
);
